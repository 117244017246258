<template>
    <div>
        <div class="col-md-12">
            <div class="row" style="margin-top:20px">
                <div class="col-md-6">
                    <h3 class="page-header">
                        <i class="fa fa fa-users animated bounceInDown show-info"></i>
                        Customer Report
                    </h3>
                </div>
                <div class="col-md-6 ">
                    <div class="row" style="padding-top:18px;justify-content:flex-end;">
                        <div class="col-md-2" style="margin-right:25px">
                            <button @click="back()" class="btn btn-primary"><i class="fa fa-backward"></i> Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style="background-color:white" v-if="edititem != null && localviewno == 1">
            <div class="info-box  bg-sheme  text-white">
                <div class="info-icon bg-sheme-dark" style="padding:10px;">
                    <h5 style="margin-top:12px">Customer Details</h5>
                </div>
                <div class="info-details" style="padding-left:2px">
                    <div class="col-md-12 flex-around-row">
                        <div class="col-md-2">
                            <h5 class="grd-bg-Dark">Customer ID- </h5>
                        </div>
                        <div class="col-md-3">
                            <h3 style="margin-right:2px" class="custid">{{ edititem.generatedid }}
                                <span v-if="edititem.verified == 1"><i class='bx bxs-badge-check'
                                        style="color:#0299f7;font-size: 19px;"></i></span>
                            </h3>
                        </div>
                        <div class="col-md-3">
                            <h5 class="grd-bg-Dark">Re-Payment Mode - </h5>
                        </div>
                        <div class="col-md-3">
                            <h3 style="margin-right:2px" class="custid" v-if="edititem.paymentmode != null">{{
                                edititem.paymentmode?.name }}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="panel panel-cascade" style="padding-bottom:20px">
                <div class="panel-body">
                    <div class="form-horizontal cascde-forms">
                        <table class="table table-bordered ">
                            <tbody class="table-font">
                                <tr>
                                    <td class="mw-30" style="padding-bottom:0px;width:25%;">
                                        <div class="text-left text">
                                            <a :href="profileimageurl" target="_blank">
                                                <img :src="profileimageurl" width="50px" height="50px" />
                                            </a>
                                        </div>
                                        <div class="text1">{{ edititem.name }}</div>
                                    </td>
                                    <td>
                                        <div class="text-left text">{{
                                            getMasterLabelByType(edititem.relationship_type,MASTER_RELATIONSHIP_TYPES)
                                            }}</div>
                                        <div class="text1"><template>{{ edititem.relation }}</template></div>
                                    </td>
                                    <td>
                                        <div class=" text ">Mobile No:</div>
                                        <div class="  "><display-mobiles :mobiles="edititem.mobiles"></display-mobiles>
                                        </div>
                                    </td>
                                    <td style="padding-bottom:0px">
                                        <div class="text-left text ">Introducer Details:</div>
                                        <div class="text1">
                                            <template v-if="edititem.referraluser != null">
                                                {{ edititem.referraluser?.generatedid }}
                                                {{ edititem.referraluser?.name }}
                                                <display-mobiles
                                                    :mobiles="edititem.referraluser.mobiles"></display-mobiles>
                                            </template>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="text-left text ">Address:</div>
                                        <div class="text1"><display-first-address
                                                :addresses="edititem.addressess"></display-first-address></div>
                                    </td>
                                    <td>
                                        <div class="text-left text ">Landmark:</div>
                                        <div class="text1" v-if="edititem.addressess != null">
                                            <span v-for="item in edititem.addressess" :key="item.id">
                                                {{ item.landmark }}
                                            </span>
                                        </div>
                                        <div class="text-left text ">Location:</div>
                                        <div class="text1">
                                            <a :href="edititem.currentlocation"
                                                target="_blank">{{ edititem.currentlocation }}</a>
                                        </div>
                                    </td>

                                    <td>
                                        <div class="text-left text ">Email :</div>
                                        <div class="text1  ">{{ edititem.email }}</div>
                                        <div class="text-left text ">Gender :</div>
                                        <div class="text1">{{ getMasterLabelByType(edititem.gender, MASTER_GENDER) }}</div>
                                    </td>
                                    <td style="padding-bottom:0px">
                                        <div class="text-left text ">Reference Name :</div>
                                        <div class="text1">{{ edititem.refername }}</div>
                                        <div class="text-left text ">Reference Mobile No :</div>
                                        <div class="text1">{{ edititem.refermobileno }}</div>
                                        <div class="text-left text ">Reference Address :</div>
                                        <div class="text1">{{ edititem.referaddress }}</div>
                                        <div class="text-left text ">Reference Relation :</div>
                                        <div class="text1">{{ edititem.referrelation }}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="mw-30">
                                        <div class="text-left text">D.O.B:</div>
                                        <div class="text1 ">{{ moment(edititem.dob).format('DD-MM-YYYY') ?? 'N/A' }}</div>
                                    </td>

                                    <td>
                                        <div class="text-left text ">Occupation</div>
                                        <div class="text1">{{ edititem.occupation }}</div>
                                    </td>
                                    <!-- <td >
                                    <div class="text-left text ">Entered By:</div>
                                    <div class="text1" ><span v-if="edititem.enteredby!=null">{{edititem.enteredby.name}}</span></div>
                                </td> -->
                                    <td class="mw-30" style="padding-bottom:0px;width:25%;">
                                        <div class="text-left text">Adhaar No.</div>
                                        <div class="text1">{{ edititem.adhar }}</div>
                                    </td>
                                    <td>
                                        <div class="text-left text ">Regestration Date</div>
                                        <div class="text1">{{ moment(edititem.registation_date).format('DD-MM-YYYY') ??
                                            'N/A'}}</div>
                                    </td>
                                </tr>
                                <tr>
                                    <td scope="row">
                                        <div class="text-left text ">Zone:</div>
                                        <div class="text1 ">{{ getMasterLabelByType(edititem.zoneid, MASTER_ZONE_TYPES) }}
                                        </div>
                                    </td>
                                    <td colspan="2" @dblclick="openhint()">
                                        <div class="text-left text">Permanent ADD. of Customer if any or Other Hint:
                                        </div>
                                        <div class="text1" v-if="showhint">{{ edititem.cus_hint }}</div>
                                        <div class="text1" v-if="showhint == false">
                                            <textarea cols="30" rows="5" v-model="cus_hint"
                                                :placeholder="edititem.cus_hint"></textarea>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="text-left text ">Verified By:</div>
                                        <div class="text1" v-if="edititem.verifiedby != null">
                                            {{ edititem.verifiedby?.name }}<br>
                                            <display-mobiles :mobiles="edititem.verifiedby.mobiles"></display-mobiles>
                                        </div>

                                    </td>
                                </tr>

                                <tr>
                                    <td scope="row">
                                        <div class="text-left text ">First Document Front:</div>
                                        <div class="text1">
                                            <display-id-proof-front-back :labelid="1"
                                                frontorback="1"></display-id-proof-front-back>
                                        </div>
                                    </td>
                                    <td scope="row">
                                        <div class="text-left text ">First Document Back:</div>
                                        <div class="text1">
                                            <display-id-proof-front-back :labelid="1"
                                                frontorback="2"></display-id-proof-front-back>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="text-left text ">Second Document:</div>
                                        <div class="text1">
                                            <display-id-proof-front-back :labelid="2"
                                                frontorback="1"></display-id-proof-front-back>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="text-left text ">Third Document:</div>
                                        <div class="text1 ">
                                            <display-id-proof-front-back :labelid="2"
                                                frontorback="2"></display-id-proof-front-back>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="text-left text ">Fourth Document:</div>
                                        <div class="text1 ">
                                            <display-id-proof-front-back :labelid="4"
                                                frontorback="1"></display-id-proof-front-back>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="text-left text ">Entry Date:</div>
                                        <div class="text1">
                                            <div>{{ edititem.enteredby?.name }}</div>
                                            <div>{{ moment(edititem.created_at).format('DD-MM-YYYY') ?? 'N/A' }}</div>
                                            <div>{{ moment(edititem.created_at).format("hh:mm a") ?? 'N/A' }}</div>
                                        </div>

                                    </td>
                                    <td>
                                        <div class="text-left text ">Advisor Remark:</div>
                                        <div class="text1"><template
                                                v-if="edititem.advisorremark != null">{{ edititem.advisorremark }}</template>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="text-left text ">Ratings: <button
                                                :style="ratingcolor()">{{ edititem.customer_type }}</button></div>
                                        <div class="text1"><template v-if="rating != null">{{ rating.ratingremark }}
                                                <br><button class="badge btn-prime btn-xs"
                                                    @click="ratingview()">View</button></template>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="text-left text ">Reward Points :</div>
                                        <div class="text1">
                                            <span class="blink_span">{{ this.allpoints  ?? 0}}</span> 
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td v-if="loginusercheck(34)">
                                        <button class="btn btn-primary-edit" @click="edit()"><i
                                                class="fa fa-edit"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div>
        <customer-sales v-if="localviewno == 1" :edititem="edititem"></customer-sales>
        <rating v-if="localviewno == 2" :edititem="edititem"></rating>
        <sales-details v-if="localviewno == 3"></sales-details>
    </div>

</template>
<script>
import Constants from '../../components/utilities/Constants.vue'
import { mapGetters } from 'vuex'
import DisplayFirstAddress from '../../components/address/DisplayFirstAddress.vue';
import DisplayMobiles from '../../components/mobile/DisplayMobiles.vue';
import DisplayIdProofFrontBack from '../../components/idproof/DisplayIdProofFrontBack.vue'
import moment from 'moment'
import Rating from '../../components/rating/Ratings.vue'
import CustomerSales from './CustomerSales.vue'
import SalesDetails from '@/pages/salesmanager/SalesDetails'

export default {
    mixins: [Constants],
    components: {
        DisplayFirstAddress,
        DisplayMobiles,
        DisplayIdProofFrontBack,
        Rating,
        CustomerSales,
        SalesDetails
    },
    data() {
        return {
            moment: moment,
            rating: [],
            localviewno: 1,
            showhint: true,
            cus_hint: '',
            allpoints: 0
        }
    },
    mounted() {
        this.refresh();
        // this.$store.dispatch("fetchmaster")
    },
    computed: {
        ...mapGetters([
            'edititem', 'viewno'
        ]),
        profileimageurl() {
            if (this.edititem != null) {
                if (this.edititem.profileimage != null) {
                    return this.edititem.profileimage.fullurl;
                }
            }
            return '#'
        }
    },
    methods: {
        openhint() {
            this.showhint = !this.showhint
            if (this.cus_hint != '') {
                let param = { id: this.edititem.id, column: "cus_hint", value: this.cus_hint }
                this.$notify({ text: 'Submitted', type: 'warn' })
                this.$http.post('api/user/updatefield', param)
                    .then((response) => this.processUpdateResponse(response.data))
                    .catch((err) => {
                        console.log(err)
                    });
            }
        },
        processUpdateResponse() {
            this.$notify({ text: 'Submitted successfully', type: 'success' })
        },
        ratingview() {
            // this.$store.commit("assignviewno",4)
            this.localviewno = 2
        },
        ratingcolor() {
            if (this.edititem.customer_type == 'A' || this.edititem.customer_type == 'B') {
                return "background:green;color:white;border:none;"
            } else if (this.edititem.customer_type == 'C') {
                return "background:orange;color:white;border:none"
            } else if (this.edititem.customer_type == 'D' || this.edititem.isdefaulter == 1) {
                return "background:red;color:white;border:none"
            }
        },
        refresh() {
            if (this.edititem.addressess != null && this.edititem.addressess.length > 0) {
                let normaladdress = this.edititem.addressess.filter(block => block.addresstype == 0)
                if (typeof normaladdress !== 'undefined' && normaladdress.length > 0) {
                    this.$store.commit('assignselectedaddress', normaladdress[0])
                }
                let workaddress = this.edititem.addressess.filter(block => block.addresstype == 1)
                if (typeof workaddress !== 'undefined' && workaddress.length > 0) {
                    this.$store.commit('assignselectedwaddress', workaddress[0])
                }
            }

            //fetching ratings by a customerid
            let param = { id: this.edititem.id }
            this.$http.post('api/fetch/ratings/byid', param)
                .then((response) => this.processratingResponse(response.data))
                .catch((err) => {
                    console.log(err)
                });
            this.$store.commit('assignmobiles', this.edititem.mobiles == null ? [] : this.edititem.mobiles)
            this.$store.commit('assignaddressess', this.edititem.addressess == null ? [] : this.edititem.addressess)
            this.$store.commit('assignidproofs', this.edititem.idproofs == null ? [] : this.edititem.idproofs)
            this.$store.commit('assignreferraluser', this.edititem.referraluser)
            this.fetchPoints()
        },
        fetchPoints() {
            let param = { customerid: this.edititem.id }
            this.$http.post('api/customer/points/fetch', param)
                .then((response) => {
                    this.allpoints = response.data?.ledger?.balance ?? 0
                })
                .catch((err) => {
                    console.log(err)
                });
        },
        processratingResponse(data) {
            this.rating = data
        },
        close() {
            this.$store.commit('assignviewno', 1)
        },
        edit() {
            this.$store.commit('assignedititem', this.edititem)
            this.$store.commit('assignviewno', 6)
        },
        back() {
            this.$store.commit('assignviewno', 1)
            if (this.$router.currentRoute.path == '/customerdetails') {
                this.$router.back();
            }
        },

    }
}
</script>